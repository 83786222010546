<template>
  <div class="m-payments-modal" data-t="m-payments-modal">
    <Deposit v-if="selectedTabId === 'deposit'" platform="mobile" />
    <Withdrawal v-if="selectedTabId === 'withdrawal'" platform="mobile" />
    <BuyCrypto
      v-if="isEnabledBuyCrypto && selectedTabId === 'buy'"
      platform="mobile"
    />
  </div>
</template>

<script setup lang="ts">
import BuyCrypto from '../BuyCrypto/BuyCrypto.vue'

const route = useRoute()
const selectedTabId = computed(() => route.query.tab?.toString() ?? 'deposit')
const { isEnabledBuyCrypto } = useBuyCryptoEnabled()
</script>

<style scoped>
.m-payments-modal {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
